<template>
  <section class="first-section">
    <div class="bg-wrapper">
      <img :src="require('../../assets/img/sec_1_bg.png')" class="img-fluid" alt="" />
    </div>
    <div class="container my-container">
      <div class="heading">
        <h1 class="head-top">Cultivate. Educate. Serve. Preserve.</h1>
        <h3 class="subhead-top">No wasted arrow. QuiverShare.</h3>
      </div>
      <div class="login-container">
        <div class="row">
          <div class="col-md-7">
            <div class="hero-container">
              <div class="graphic-hero">
                <img :src="require('../../assets/img/sec_1/g1.png')" alt="" class="img-fluid" />
              </div>
              <div class="verses-container">
                <h5><span class="dot"></span> Psalm 127:3-5 (NASB)</h5>
                <p>
                  Behold, children are a gift of the Lord, The fruit of the womb
                  is a reward. Like arrows in the hand of a warrior, So are the
                  children of one’s youth. Blessed is the man whose
                  <span class="highlight">QUIVER </span> is full of them; They
                  will not be ashamed When they speak with their enemies in the
                  gate.
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <div class="login-sec">
              <h3>Login</h3>
              <p class="sub-label">Please provide account information below.</p>
              <form class="form-login" @submit.prevent="login">
                <div class="mb-3">
                  <label for="user_email" class="form-label">Email address</label>
                  <input
                    v-model="form.email"
                    type="email"
                    :class="formControl(v$.form.email)"
                    id="user_email"
                    aria-describedby="emailHelp"
                    placeholder="Enter email address"
                    :disabled="loading"
                  />
                  <div v-if="v$.form.email.$error" class="invalid-feedback">
                    <span>{{ errorMessage(v$.form.email.$errors[0].$validator, 'Email address') }}</span>
                  </div>
                </div>
                <div class="mb-4">
                  <label for="user_pass" class="form-label">Password</label>
                  <input
                    v-model="form.password"
                    type="password"
                    :class="formControl(v$.form.password)"
                    id="user_pass"
                    placeholder="Enter password"
                    :disabled="loading"
                  />
                  <div v-if="v$.form.password.$error" class="invalid-feedback">
                    <span>{{ errorMessage(v$.form.password.$errors[0].$validator, 'Password') }}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <div class="mb-4 form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="exampleCheck1"
                        :disabled="loading"
                      />
                      <label class="form-check-label rem-label" for="exampleCheck1">Remember me</label>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="mb-4 forgot-container">
                      <a href="#" data-bs-toggle="modal" data-bs-target="#modal_forgot">Forgot Password ?</a>
                    </div>
                  </div>
                </div>
                <div class="d-grid gap-3 btn-grp">
                  <button class="btn btn-login" type="submit" :disabled="loading">Login</button>
                  <button class="btn btn-join" type="button" @click="$router.push('/join-us')" :disabled="loading">Join Us</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { mapActions } from 'vuex';

export default defineComponent({
  data() {
    return {
      loading: false,
      form: {
        email: null,
        password: null
      }
    };
  },
  setup: () => ({ v$: useVuelidate() }),
  validations () {
      return {
          form: {
              email: { required },
              password: { required }
          }
      };
  },
  methods: {
    async login() {
      const isFormCorrect = await this.v$.$validate();
      if(!isFormCorrect) return;
      this.loading = true;
      try {
        await this.authenticate({
          strategy: 'local',
          email: this.form.email,
          password: this.form.password
        });
      } catch(err) {
        this.$toast(err.message, {
            duration: 3000,
            styles:  {
                fontSize: '11pt',
                backgroundColor: '#ce4f4f'
            },
            slot: '<i class="fa fa-exclamation"></i>'
        });
      } finally {
        this.loading = false;
      }
    },
    errorMessage(type, name) {
        switch(type) {
            case 'required': return `${name} is required`;
        }
    },
    formControl(form) {
      return {
        'form-control': true,
        'is-invalid': form.$dirty && form.$invalid,
        'is-valid': form.$dirty && !form.$invalid
      };
    },
    ...mapActions('auth', ['authenticate'])
  }
});
</script>