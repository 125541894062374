<template>
  <section class="second-section" id="cultivate">
    <div class="container my-container cultivate-container">
      <div class="row">
        <div class="col-6 col-md-6">
          <div class="head-title">
            <h5>Cultivate</h5>
            <h1>Free Christian Resources</h1>
          </div>
          <div class="content-text-container">
            <h3 class="text-ques">
              Looking for a reliable Bible-based reading material?
            </h3>
            <p class="text-norm">
              We offer many different Biblical articles (books, texts, magazines
              etc.) with real-time reviews from Christian members and with
              guidance from Christian pastors and leaders. You can have or use
              these books <span class="highlight">FOR FREE</span>.
              <br />
              <br />
              Our registration is <span class="highlight">FREE</span> for all
              active members of Christian churches.
            </p>
            <div class="d-grid gap-2 col-7">
              <button class="btn btn-join-now" type="button" @click="$router.push('/join-us')">Join Now</button>
            </div>
          </div>
          <div class="verses-container">
            <h5><span class="dot"></span> Proverbs 1:5 (NASB)</h5>
            <p>
              A wise person will hear and increase in learning, And a person of
              understanding will acquire wise counsel,
            </p>
          </div>
        </div>
        <div class="col-6 col-md-6">
          <div class="g2-container">
            <img :src="require('../../assets/img/home/g2.png')" alt="" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>