<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="modal_success"
    tabindex="-1"
    aria-labelledby="modal_successLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content modal-forgot-success-content">
        <div class="modal-body">
          <div class="head-mod-container">
            <div class="icon-stat-container">
              <img class="img-fluid" :src="require('../../assets/img/check_success.svg')" alt="" />
            </div>

            <h3 class="head-modal-title">Successful</h3>
            <p class="des-modal">
              We sent a link to your email that will allow you to change your
              password. Please check your email.
              <br />Thank you.
            </p>
          </div>
          <div class="d-grid gap-2">
            <button
              type="button"
              class="btn btn-ok-close"
              data-bs-dismiss="modal"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End Modal -->
</template>