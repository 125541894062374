import FirstSection from './first-section.vue';
import SecondSection from './second-section.vue';
import ThirdSection from './third-section.vue';
import FourthSection from './fourth-section.vue';
import FifthSection from './fifth-section.vue';
import SixthSection from './sixth-section.vue';

export default {
    FirstSection,
    SecondSection,
    ThirdSection,
    FourthSection,
    FifthSection,
    SixthSection
}