<template>
  <section class="third-section" id="educate">
    <div class="container my-container educate-container">
      <div class="row">
        <div class="col-6 col-md-6"></div>
        <div class="col-6 col-md-6">
          <div class="skirt-grp">
            <img
              :src="require('../../assets/img/home/side_skirt1.png')"
              alt=""
              class="skirt-1 img-fluid"
            />
            <img
              :src="require('../../assets/img/home/side_skirt2.png')"
              alt=""
              class="skirt-2 img-fluid"
            />
          </div>
          <div class="educate-content-text-container">
            <div class="head-title">
              <h5>Educate</h5>
              <h1>Free Homeschooling <br />Resources</h1>
            </div>

            <h3 class="text-ques">
              How is your quiver and arrows, my warrior friend?
            </h3>
            <p class="text-norm">
              We offer <span class="highlight">FREE</span> textbooks, workbooks,
              school supplies and materials that you may use to fulfill your
              noble calling and responsibility of teaching and guiding your
              children.
              <br />
              <br />
              Our registration is <span class="highlight">FREE</span> for all
              active members of Christian churches.
            </p>
            <div class="d-grid gap-2 col-7">
              <button class="btn btn-join-now" type="button" @click="$router.push('/join-us')">Join Now</button>
            </div>

            <div class="verses-container">
              <h5><span class="dot"></span> Isaiah 54:13-14 (NKJV)</h5>
              <p>
                All your children shall be taught by the Lord,
                <br />and great shall be the peace of your children. In
                righteousness you shall be established; You shall be far from
                oppression, for you shall not fear; And from terror, for it
                shall not come near you.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>