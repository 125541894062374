<template>
  <first-section />
  <second-section />
  <third-section />
  <fourth-section />
  <fifth-section />
  <sixth-section />
  <modal-forgot />
  <modal-success />
</template>

<script>
import Components from '../components/home.components';
import ModalForgot from '../components/modals.component/modal-forgot.vue';
import ModalSuccess from '../components/modals.component/modal-success.vue';

export default {
  name: 'Home',
  data() {
    return {
      targethash: window.location.hash,
      currentInterval: null
    };
  },
  mounted() {
    const el = window.$(window.location.hash).get(0);
    if(el) this.scrollMeTo(el);
    this.currentInterval = setInterval(() => {
      if(this.targethash !== window.location.hash) {
        this.targethash = window.location.hash;
        if(!window.location.hash) {
          window.scrollTo(0, 0);
        } else {
          const el = window.$(window.location.hash).get(0);
          if(el) this.scrollMeTo(el);
        }
      }
    }, 100);
  },
  unmounted() {
    clearInterval(this.currentInterval);
  },
  methods: {
    scrollMeTo(element) {
      var top = element.offsetTop;
      window.scrollTo(0, top);
    }
  },
  components: {
    ...Components,
    ModalForgot,
    ModalSuccess
  }
}
</script>
