<template>
  <section class="fourth-section" id="serve">
    <div class="container my-container serve-container">
      <div class="row">
        <div class="col-6 col-md-6">
          <div class="head-title">
            <h5>Serve</h5>
            <h1>Serve One Another</h1>
          </div>
          <div class="content-text-container">
            <h3 class="text-ques">
              Wondering how you can serve without <br />cost on your part?
            </h3>
            <p class="text-norm">
              Check out your bookshelves and garage. Many of our brothers and
              sisters will be blessed if you will choose to share those precious
              articles with them by simply clicking a few buttons. You may
              choose to lend it or give it for free. Let us serve and help one
              another.
              <br />
              <br />
              Our registration is <span class="highlight">FREE</span> for all
              active members of Christian churches.
            </p>
            <div class="d-grid gap-2 col-7">
              <button class="btn btn-join-now" type="button" @click="$router.push('/join-us')">Join Now</button>
            </div>
          </div>
          <div class="verses-container">
            <h5><span class="dot"></span> Acts 4:32 (NASB)</h5>
            <p>
              And the congregation of those who believed were of one heart and
              soul; and not one of them claimed that anything belonging to him
              was his own,but all things were common property to them.
            </p>
          </div>
        </div>
        <div class="col-6 col-md-6">
          <div class="g2-container">
            <img :src="require('../../assets/img/home/g8.png')" alt="" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>