<template>
  <!-- Modal -->
  <div ref="modal" class="modal fade" id="modal_forgot" tabindex="-1" aria-labelledby="modal_forgotLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content modal-forgot-content">
        <div class="modal-body">
          <div class="head-mod-container">
            <h3 class="head-modal-title">Forgot Password</h3>
            <p class="des-modal">
              Please enter the email address used
              <br />with your account.
            </p>
          </div>
          <form class="forgot-form">
            <div class="mb-3">
              <label for="email-user" class="col-form-label">Email</label>
              <!-- <input type="text" placeholder="Enter your email" class="form-control" id="email-user" /> -->
              <input v-model="form.email" type="text" placeholder="Enter your email" :class="formControl(v$.form.email)" id="email-user" required :disabled="loading">
              <div v-if="v$.form.email.$error" class="invalid-feedback">
                  <span>{{ errorMessage(v$.form.email.$errors[0].$validator, 'Email address') }}</span>
              </div>
            </div>
          </form>
          <div class="d-grid gap-2">
            <button type="button" class="btn btn-submit-forgot" @click="submit">
              Submit
            </button>
            <button type="button" class="btn btn-cancel-modal" data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End Modal -->
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';

export default defineComponent({
  data() {
    return {
      loading: false,
      emailExists: false,
      form: {
        email: null
      }
    };
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations () {
    return {
        form: {
            email: { required, email, emailCheck: this.emailCheck }
        }
    };
  },
  methods: {
    async submit() {

      this.emailExists = false;

      await this.finduser({
          query: { email: this.form.email }
      })
      .then(result => result.total === 0)
      .then(result => new Promise((resolve) => {
          this.v$.form.$reset();
          this.emailExists = result;
          setTimeout(() => {
              this.v$.form.$touch();
              resolve(result);
          }, 100);
      }));

      const isFormCorrect = await this.v$.$validate();
      if(!isFormCorrect) return;

      try {
        this.loading = true;
        await this.createRequest(this.form);
        window['$'](this.$refs.modal).modal('hide');
        window['$']('#modal_success').modal('show');
      } catch(err) {
        this.$toast(err.message, {
            duration: 3000,
            styles:  {
                fontSize: '11pt',
                backgroundColor: '#ce4f4f'
            },
            slot: '<i class="fa fa-exclamation"></i>'
        });
      } finally {
        this.loading = false;
      }
    },
    formControl(form) {
        return {
            'form-control': true,
            'is-invalid': form.$dirty && form.$invalid,
            'is-valid': form.$dirty && !form.$invalid
        };
    },
    errorMessage(type, name) {
        switch(type) {
            case 'required': return `${name} is required`;
            case 'email': return `${name} is not a valid email address`;
            case 'emailCheck': return 'Email address is not exists';
        }
    },
    emailCheck() {
        return !this.emailExists;
    },
    ...mapActions('users', { finduser: 'find' }),
    ...mapActions('forgot-password-request', { createRequest: 'create' })
  }
})
</script>
