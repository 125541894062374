<template>
  <section class="fifth-section" id="preserve">
    <div class="container-fluid">
      <img
        :src="require('../../assets/img/home/divider_l.png')"
        alt=""
        class="img-fluid divider-arrow"
      />
    </div>
    <div class="g7-container">
      <img :src="require('../../assets/img/home/g7.png')" alt="" />
    </div>
    <div class="container my-container preserve-container">
      <div class="row">
        <div class="col-6 col-md-6"></div>
        <div class="col-6 col-md-6">
          <div class="head-title">
            <h5>Preserve</h5>
            <h1>Strong Christian Heritage</h1>
          </div>
          <div class="content-text-container">
            <h3 class="text-ques">
              Looking for active means to preserve our <br />Christian heritage?
            </h3>
            <p class="text-norm">
              You are welcome to participate in our ministry of preserving
              Christian heritage through intentional circulation of Christian
              articles and real-time discussions on topics and issues that
              Christians face nowadays.
              <br />
              <br />
              Our registration is <span class="highlight">FREE</span> for all
              active members of Christian churches.
            </p>
            <div class="d-grid gap-2 col-7">
              <button class="btn btn-join-now" type="button" @click="$router.push('/join-us')">Join Now</button>
            </div>
          </div>
          <div class="verses-container">
            <h5><span class="dot"></span> 1 Peter 3:14-15 (NASB)</h5>
            <p>
              But even if you should suffer for the sake of righteousness, you
              are blessed. And do not fear their intimidation, and do not be in
              dread, but sanctify Christ as Lord in your hearts, always being
              ready to make a defense to everyone who asks you to give an
              account for the hope that is in you, but with gentleness and
              respect
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <img
        :src="require('../../assets/img/home/divider_r.png')"
        alt=""
        class="img-fluid divider-arrow"
      />
    </div>
  </section>
</template>