<template>
  <section class="sixth-section" id='verses'>
    <div class="container my-container verses-content-container">
      <div class="row">
        <div class="col-6 col-md-6">
          <div class="verses-container">
            <h5><span class="dot"></span> John 3:16-17 (NASB)</h5>
            <p>
              For God so loved the world, that He gave His only Son, so that everyone who believes in Him will not perish, but have eternal life. For God did not send the Son into the world to judge the world, but so that the world might be saved through Him.
            </p>
          </div>
        </div>
        <div class="col-6 col-md-6">
          <div class="g5-container">
            <img :src="require('../../assets/img/home/g5.png')" alt="" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6 col-md-6">
          <div class="g6-container">
            <img :src="require('../../assets/img/home/g6.png')" alt="" />
          </div>
        </div>
        <div class="col-6 col-md-6">
          <div class="verses-container">
            <h5><span class="dot"></span> Romans 10:8-10 (NASB)</h5>
            <p>
              But what does it say? “The word is near you, in your mouth and in your heart”—that is, the word of faith which we are preaching, that if you confess with your mouth Jesus as Lord, and believe in your heart that God raised Him from the dead, you will be saved; for with the heart a person believes, resulting in righteousness, and with the mouth he confesses, resulting in salvation.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>